import { createRouter, createWebHistory } from 'vue-router';
import Orders from '@/pages/Orders';
import Cleaners from '@/pages/Cleaners';
import Expenses from '@/pages/Expenses';
import Todo from '@/pages/Todo';
import Calendar from '@/pages/Calendar';

const routes = [
  {
    id: 1,
    path: '/',
    component: Orders,
    name: "Orders",
  },
  {
    id: 2,
    path: '/cleaners',
    component: Cleaners,
    name: "Cleaners",
  },
  {
    id: 3,
    path: '/expenses',
    component: Expenses,
    name: "Expenses",
  },
  {
    id: 4,
    path: '/todo',
    component: Todo,
    name: "Todo",
  },
  {
    id: 5,
    path: '/calendar',
    component: Calendar,
    name: "Calendar",
  },
]

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL)
})

export default router
