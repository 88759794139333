<template>
  <div class="pageEntry">
    <div class="modal">
      <h3>Entry</h3>
      <small v-if="error">Неправильный логин или пароль</small>
      <input v-model.trim="login" type="text" placeholder="login">
      <input v-model.trim="password" type="text" placeholder="password">
      <button @click="checkEntry({ login, password })">Send</button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  data() {
    return {
      login: '',
      password: '',
    }
  },
  methods: {
    checkEntry(obj) {
      this.entry(obj)
          .then(res => {
            if (!res || res.status === 'fail') throw "Ошибка при входе";
            this.setError(false);
            this.setIsLoggedIn({isLoggedIn: true, userRoot: res.userRoot, userID: res.userID});
            localStorage.entry_key = res.entry_key
          })
          .catch(err => {
            alert(err);
          })
    },
    ...mapActions({
      entry: 'post/entry',
    }),
    ...mapMutations({
      setError: 'post/setError',
      setIsLoggedIn: 'post/setIsLoggedIn',
    }),
  },
  computed: {
    ...mapState({
      error: state => state.post.error,
    }),
  }
}
</script>

<style scoped>
.modal {
  width: 310px;
  height: 300px;
  margin: 0 auto;
  padding: 10px;
  box-shadow: rgb(100 100 111 / 20%) 0px 0px 3px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 20px;
}

.pageEntry {
  display: flex;
  align-items: center;
  height: 100vh;
}

.pageEntry input {
  border: none;
  box-shadow: rgb(100 100 111 / 30%) 0px 0px 4px 1px;
  border-radius: 30px;
  padding: 10px;
}

.pageEntry button {
  text-align: center;
  padding: 15px;
  border-radius: 30px;
  background: #d3d3d3;
  border: none;
  font-weight: bold;
}

.pageEntry button:hover {
  background: #3572ff;
  color: white;
}

small {
  color: red;
  font-weight: bold;
}
</style>