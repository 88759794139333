<template>
  <tr :style="getColorOperator(order[0][0].Operator)" @click="showOrderDetails(order)">
    <td>{{ getFormatDate(order[0][0].OrderDate) }}</td>

    <td v-if="!order[0][0].OrderID"></td>
    <td v-else>{{ order[0][0].OrderID }}</td>

    <td>{{ order[0][0].ClientName }}</td>
    <td class="adress">{{ order[0][0].OrderAdress }}</td>
    <td>{{ format(order[0][0].OrderPrice) }} <span class="rub">р.</span></td>

    <td v-if="this.userRoot === 1">{{ format(salaryCleanersInOrder(order[1])) }} <span class="rub">р.</span></td>

    <td v-if="this.userRoot === 1">{{ format(order[0][0].PercentageBroker) }} <span class="rub">р.</span></td>
    <td v-if="this.userRoot === 1">{{ order[0][0].ClientPhone }}</td>
    <td v-if="this.userRoot === 1">{{ format(order[0][0].OrderExpenses) }} <span><span class="rub">р.</span></span></td>
    <td v-if="this.userRoot === 1">{{ order[0][0].NameExpense }}</td>
    <td>
      <div class="div-cleaners" v-for="(cleaner, index) in order[1]" :cleaner="cleaner" :key="cleaner.ID">
        <span>
          {{ cleaner.Cleaner }}<span v-if="order[1][index + 1]">,</span>
        </span>

      </div>
    </td>
    <td class="adress">{{ order[0][0].OrderComment }}</td>
    <td v-if="this.userRoot === 1">{{ order[0][0].OurSite }}</td>
  </tr>
  <tr :class="'orderCleaners hide c-' + order[0][0].ID" v-for="cleaner in order[1]" :cleaner="cleaner" :key="cleaner.ID">
    <td class="cleaner">Клинер</td>
    <td>{{ cleaner.Cleaner }}</td>
    <td>{{ format(cleaner.CleanerSalary) }} руб.</td>
  </tr>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      userRoot: state => state.post.userRoot,
      operators: state => state.post.operators,
    }),
  },
  methods: {
    getColorOperator(operatorID){
      if(!operatorID) return "";
      for(let i in this.operators){
        if(this.operators[i].ID == operatorID) return `background-color: ${this.operators[i].color};`;
      }
      return "";
    },
    ...mapMutations({
      setOrderActive: 'post/setOrderActive',
    }),
    format(a) {
      var point = "";
      //Пока удаляю, но сохраняю целую часть...
      var x = String(a).replace(/(\.|,)\d+/, function (m) {
        point = m;
        return "";
      });

      x = x.split("").reverse().join("")
        .replace(/(\d{3})/g, "$1 ")
        .split("").reverse().join("");
      //Разбил в массив, перевернул, расставил пробелы, перевернул обратно.
      return x + point;
    },
    salaryCleanersInOrder(order) {
      let salary = 0;
      for (let i in order) {
        salary += order[i].CleanerSalary;
      }
      return salary;
    },

    getFormatDate(date) {
      const dateMatch = new Date(date);
      dateMatch.setDate(dateMatch.getDate());
      const dateMatchFormat = ('0' + dateMatch.getDate()).slice(-2) + '-' + ('0' + (dateMatch.getMonth() + 1)).slice(-2) + '-' + dateMatch.getFullYear();
      return dateMatchFormat;
    },
    showOrderDetails(orderID) {
      // if(this.userRoot !== 1) return;
      this.setOrderActive(orderID);
      // var elems = document.getElementsByClassName(elementClass);
      // for (var i = 0; i < elems.length; i += 1) {
      //     elems[i].classList.toggle("hide");
      // }

      // @click="hideCleaners('c-' + order[0][0].ID)" :class="'order o-' + order[0][0].ID"
    }
  },
}
</script>

<style scoped>
img {
  width: 20px;
}

.operator {
  background-color: rgb(255, 251, 136);
}

tr {
  background: #ebebeb;
  border-radius: 20px;
  border: none;
  height: 35px;
}

.table th,
.table td {
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  position: relative;
}

.table tr:hover td {
  background: #ffffff;
}

.table tr:hover td:after {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 105%;
}

/* Рамка слева у первой ячейки */
.table tr:hover td:first-child:after {
  border-left: 3px solid orange;
}

/* Рамка справа у последний ячейки */
.table tr:hover td:last-child:after {
  border-right: 3px solid orange;
  width: auto;
}

.link {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.link:hover {
  color: #75baff;
}

.cleaner {
  background: blue !important;
  color: white;
}

.hide {
  display: none;
}

.rub {
  color: gray;
  font-size: 10px;
}

.adress {
  max-width: 240px;
  overflow: auto;
}


td {
  border: 1px solid #d7d7d7;
}
</style>