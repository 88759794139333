<template>
    <div>
        <div v-if="isLoggedIn">
            <div class="addOrder">
                Расходы за
                <select v-model="getIncomeMonth">
                    <option value="1">Январь</option>
                    <option value="2">Февраль</option>
                    <option value="3">Март</option>
                    <option value="4">Апрель</option>
                    <option value="5">Май</option>
                    <option value="6">Июнь</option>
                    <option value="7">Июль</option>
                    <option value="8">Август</option>
                    <option value="9">Сентябрь</option>
                    <option value="10">Октябрь</option>
                    <option value="11">Ноябрь</option>
                    <option value="12">Декабрь</option>
                </select>
                <select v-model="getIncomeYear">
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                </select>
                <h2 v-if="orders && GeneralExpenses">{{ sumInMonthExpenses({ getIncomeMonth, getIncomeYear }) }}</h2>
            </div>
            <div class="addOrder">
                <h2>Добавить Расход </h2>
                <div class="">
                    <input v-model="expenseDate" id="date" type="date">
                    <input v-bind:class="{ error: ErrorCostExpense }" v-model="costExpense" type="number"
                        placeholder="Стоимость">
                    <input v-bind:class="{ error: ErrorExpense }" v-model="nameExpense" placeholder="Расходник">
                </div>
                <button @click="addExpense({ costExpense, nameExpense, expenseDate })">Добавить</button>
            </div>

            <div class="table">
                <table>
                    <thead>
                        <tr>
                            <th>Дата</th>
                            <th>Расходник</th>
                            <th>Стоимость</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="expense in GeneralExpenses" :expense="expense" :key="expense.ID">
                            <td>{{ getFormatDate(expense.ExpenseDate) }}</td>
                            <td>{{ expense.Expense }}</td>
                            <td>{{ expense.CostExpense }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import OrderTableBody from "../components/OrderTableBody.vue";

const date = new Date();
const todayMonth = date.getMonth() + 1;
const todayYear = date.getFullYear();
const dateMatch = new Date()

dateMatch.setDate(dateMatch.getDate())

const dateMatchFormat =
    dateMatch.getFullYear() +
    '-' +
    ('0' + (dateMatch.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + dateMatch.getDate()).slice(-2)

export default {
    components: {
        OrderTableBody
    },
    data() {
        return {
            getIncomeMonth: todayMonth,
            getIncomeYear: todayYear,
            income: '0',

            expenseDate: dateMatchFormat,
            costExpense: '',
            nameExpense: '',

            login: '',
            password: '',
        }
    },
    methods: {
        ...mapActions({
            entry: 'post/entry',
            getOrders: 'post/getOrders',
            addExpense: 'post/addExpense',
            getCleaners: 'post/getCleaners',
            arrayPlus: 'post/arrayPlus',
            getGeneralExpenses: 'post/getGeneralExpenses',
        }),

        getFormatDate(date) {
            const dateMatch = new Date(date);
            dateMatch.setDate(dateMatch.getDate());
            const dateMatchFormat = ('0' + dateMatch.getDate()).slice(-2) + '-' + ('0' + (dateMatch.getMonth() + 1)).slice(-2) + '-' + dateMatch.getFullYear();
            return dateMatchFormat;
        },

        sumInMonthExpenses(getIncome) {
            let income = 0;

            this.GeneralExpenses.forEach(val => {
                const date = new Date(val.ExpenseDate);
                const todayMonth = date.getMonth() + 1;
                const todayYear = date.getFullYear();

                if (getIncome.getIncomeMonth == todayMonth && getIncome.getIncomeYear == todayYear) {
                    income += Number(val.CostExpense)
                }
            });

            return income
        },
    },
    mounted() {
      this.getCleaners();
      this.getOrders();
      this.getGeneralExpenses();
    },
    computed: {
        ...mapState({
            orders: state => state.post.orders,
            cleaners: state => state.post.cleaners,
            sortType: state => state.post.sortType,
            sortBy: state => state.post.sortBy,
            GeneralExpenses: state => state.post.GeneralExpenses,
            isLoggedIn: state => state.post.isLoggedIn,
            error: state => state.post.error,
            ErrorExpense: state => state.post.ErrorExpense,
            ErrorCostExpense: state => state.post.ErrorCostExpense,

            searchQuery: state => state.post.searchQuery,
        }),
    }

}
</script>

<style scoped>
thead tr {
    background: #d3d3d3;
    border-radius: 20px;
    border: none;
    height: 35px;
}

tbody tr {
    background: #ebebeb;
}

.table th,
.table td {
    padding: 10px;
    text-align: center;
    vertical-align: middle;
    position: relative;
}

.table tr:hover td {
    background: #ffffff;
}

.table tr:hover td:after {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 105%;
}

.table {
    overflow: auto;
    padding-bottom: 5px;
    margin-top: 10px;
    border-radius: 30px;
}

th {
    cursor: pointer;
    padding: 0px 5px 0px 5px;
}

th:hover {
    background: #ebebeb;
}

table {
    width: 100%;
    text-align: center;
    border-spacing: unset;
    border-radius: 10px;
    white-space: nowrap;
}

table thead {
    background: #d3d3d3;
    height: 60px;
}


.sortDown:after {
    content: "▼";
    font-size: 10px;
    color: #1E90FF;
}

.sortUp:after {
    content: "▲";
    font-size: 10px;
    color: #1E90FF;
}

.addOrder {
    border-radius: 30px;
    border: 1px solid gray;
    padding: 20px;
    margin-top: 20px;
}

.createOrder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

input {
    padding: 5px;
    border-radius: 30px;
    border: 1px solid gray;
    margin: 10px;
    max-height: 40px;
}

textarea {
    padding: 5px;
    border-radius: 30px;
    border: 1px solid gray;
    margin: 10px;
}

.select-cleaner {
    width: 100%;
}

.error {
    border: 2px solid red;
}

button {
    padding: 10px;
    width: 150px;
    border-radius: 20px;
    border: none;
    background: #0072ff;
    color: white;
}

.cleanerSelect {
    border: 1px solid gray;
    padding: 5px;
    border-radius: 20px;
    margin-right: 5px;
    margin-bottom: 10px;
}

.cleanerSelect input {
    margin: 0;
}

.modal {
    width: 310px;
    height: 300px;
    margin: 0 auto;
    padding: 10px;
    box-shadow: rgb(100 100 111 / 20%) 0px 0px 3px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 20px;
}
</style>
