<template lang="">
    <div class="header">
        <div class="menu">
            <div class="menu-btn">
                <router-link class="btn-menu" active-class="active" exact to="/">Заказы</router-link>
                <router-link v-if="this.userRoot === 1" class="btn-menu" active-class="active" exact to="/cleaners">Клинеры</router-link>
                <router-link v-if="this.userRoot === 1" class="btn-menu" active-class="active" exact to="/expenses">Расходы</router-link>
                <router-link class="btn-menu" active-class="active" exact to="/todo">Планер</router-link>
                <!-- <router-link class="btn-menu" active-class="active" exact to="/calendar">Календарь</router-link> -->
                <router-link class="btn-menu" exact to="/" @click="exit()">Выход</router-link>
            </div>
        </div>
    </div>
</template>
<script>

import { mapState, mapActions, mapMutations } from "vuex";

export default {
    methods: {
        exit() {
            this.setExit();
            localStorage.entry_key = null
        },
        ...mapMutations({
            setExit: 'post/setExit',
        }),
    },
    computed: {
        ...mapState({
            userRoot: state => state.post.userRoot,
        }),
    }
}
</script>
<style scoped>
.header {
    height: 80px;
    padding: 10px;
    top: 0;
    width: 100%;
    overflow: auto;
    z-index: 1;
}

.menu {
    max-width: 1000px;
    margin: 0 auto;
    align-items: center;
    display: flex;
    justify-content: space-between;
    color: rgb(0, 0, 0);
    height: 100%;
    padding: 10px 10px 25px 10px;
    border-bottom: 1px solid #ebebeb;
}

.btn-menu {
    padding: 11px 10px 25px 10px;
    text-decoration: none;
    color: rgb(0, 0, 0);
    margin-left: 15px;
    font-weight: 500;
    transition: .3s;
}

.btn-menu:hover {
    color: #75baff;
}

.menu img {
    width: 110px;
}

.active {
    border-bottom: 2px solid #1E90FF;
}
</style>
